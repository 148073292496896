import axios from 'axios';
import { observable, decorate } from 'mobx';
import { app, firebase, config } from '../utils/firebase'
// import { database } from '../utils/firebase'
// import { useListVals, useObjectVal } from 'react-firebase-hooks/database';


//to log user once token is expired because of unuse
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && error.response && error.response.status === 403) {
    firebase.auth().signOut();
  }
  else {
    return Promise.reject(error);
  }
});


let store = null;

class Store {
  user = null;

  constructor() {
    const localUser = localStorage.getItem('user');
    if (localUser) {
      this.setUser(JSON.parse(localUser));
    }

    firebase.auth().onAuthStateChanged((user) => {
      this.setUser(user);
    })
  }

  setUser(user) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + (user.xa || (user.stsTokenManager && user.stsTokenManager.accessToken));
    }
    else {
      localStorage.removeItem('user');
    }
    this.user = user;
  }

  async login() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const { user } = await firebase.auth().signInWithPopup(provider);
    }
    catch (e) {
      console.log(e)
    }
  }

  async logout() {
    try {
      await firebase.auth().signOut();
    }
    catch (e) {
      console.log(e)
    }
  }
}

decorate(Store, {
  user: observable
})

export function initializeAuthStore() {
  if (store === null) {
    store = new Store();
  }
  return store;
}
