import { colors } from '@material-ui/core';

export default {
  root: {
    backgroundColor: '#E3F2FD',
    borderRadius: '100px',
    height: '30px',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginLeft: '10px',
    marginBottom: '10px'
  },
  label: {
    fontfamily: "'Roboto', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    /* or 150% */

    textAlign: 'center',
    letterSpacing: '0.25px',

    /* Primary / 500 - Accent */

    color: '#2196F3',
    opacity: '0.87'
  },
  deleteIcon: {
    color: '#1976D2'
  }
};
