/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/positions" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Signin'))
      },
      {
        path: '/auth/forgot',
        exact: true,
        component: lazy(() => import('src/views/ForgotPassword'))
      },
      {
        path: '/auth/signup',
        exact: true,
        component: lazy(() => import('src/views/Signup'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/positions',
        exact: true,
        component: lazy(() => import('src/views/Positions'))
      },
      {
        path: '/positions/:id',
        exact: true,
        component: lazy(() => import('src/views/PositionDetail'))
      },
      {
        path: '/positions/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/PositionDetail'))
      },
      {
        path: '/contacts',
        exact: true,
        component: lazy(() => import('src/views/Contacts'))
      },
      {
        path: '/contacts/:tab',
        exact: true,
        component: lazy(() => import('src/views/Contacts'))
      },
      {
        path: '/contacts/searches/:id',
        exact: true,
        component: lazy(() => import('src/views/SearchDetails'))
      },
      {
        path: '/templates',
        exact: true,
        component: lazy(() => import('src/views/Templates'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/nylas/callback',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
