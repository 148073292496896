import palette from './palette';

export default {
  fontFamily: 'Roboto',
  h1: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px'
  },
  h2: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 600,
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px'
  },
  h3: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: '24px',
    letterSpacing: '0px',
    // lineHeight: '28px'
    lineHeight: '36px'
  },
  h4: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px'
  },
  h5: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px'
  },
  h6: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '30px'
  },
  subtitle1: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "normal",
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    // textTransform: 'uppercase'
  },
  subtitle2: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '.1px',
    lineHeight: '21px'
  },
  body1: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
    fontWeight: 300,
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: '21px'
  },
  body2: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: '15px'
  },
  button: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  },
  tab: {
    color: palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '1.25px',
    lineHeight: '16px',
    textTransform: 'Capitalize'
  }
};
