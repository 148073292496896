import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 0,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40
    },

    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 64
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      paddingLeft: 250
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0
    },

  

  }
}));

const Dashboard = inject('AuthStore')(observer(({ AuthStore, route }) => {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  return AuthStore.user ? <>
    <TopBar AuthStore={AuthStore} onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
    <NavBar
      onMobileClose={() => setOpenNavBarMobile(false)}
      openMobile={openNavBarMobile}
      user={AuthStore.user}
    />
    <div className={classes.container}>
      <div className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </div>
    </div>
  </> : <Redirect to='/auth/login' />;
}));

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
