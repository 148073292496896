/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';


export default [
  {
    items: [
      {
        icon: AssignmentIndIcon,
        title: 'Positions',
        href: '/positions',
      },
      // {
      //   icon: GroupIcon,
      //   title: 'Contacts',
      //   href: '/contacts',
      // },
      {
        icon: FileCopyOutlinedIcon,
        title: 'Templates',
        href: '/templates',
      },
      // {
      //   icon: SettingsIcon,
      //   title: 'Settings',
      //   href: '/settings'
      // }
    ]
  }
];
