/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors,
  Button,
  Box
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import NavItem from 'src/components/NavItem';
import navConfig from './navConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 250,
  },
  desktopDrawer: {
    width: 250,
    // top: 64,
    // height: 'calc(100% - 64px)'
    height: 'calc(100%)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 1),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    // marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  companyName: {
    marginLeft: '16px',
    marginTop: '16px'
  },
  companyDivider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  displayName: {
    marginLeft: '16px',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '30px',
    marginLeft: '16px',
    letterSpacing: '0.15px',
    fontStyle: 'normal',
    lineHeight: '30px'
  },
  email: {
    marginLeft: '16px'
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items, subheader, key, ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0
}) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
}

function NavBar({
  openMobile,
  onMobileClose,
  className,
  user,
  ...rest
}) {
  const classes = useStyles();
  const location = useLocation();
  const [status, setStatus] = useState('online');

  const handleStatusToggle = () => {
    const statusSeq = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online'
    };

    setStatus((prevStatus) => statusSeq[prevStatus]);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography component="div" className={classes.companyName} variant="h3">
        <Box color="text.primary">Mayfair</Box>
      </Typography>
      <Divider className={classes.companyDivider} />

      <Typography component="div" className={classes.displayName} >
        <Box color="text.primary">{user.displayName}</Box>
      </Typography>

      <Typography component="div" className={classes.email} variant="body1">
        <Box color="text.primary" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{user.email}</Box>
      </Typography>

      <nav className={classes.navigation}>
        {navConfig.map((list) => renderNavItems({
          items: list.items,
          subheader: list.subheader,
          pathname: location.pathname,
          key: list.subheader
        }))}
      </nav>
      <Divider />
      <div className={classes.profile}>
        {/* <Avatar
          alt="Person"
          className={classes.avatar}
          src={user.photoURL}
        /> */}
        <div className={classes.details}>

          <Button
            className={classes.button, classes.buttonLeaf}
            component={NavLink}
            exact
            to={'/settings'}
          >
            <SettingsIcon className={classes.icon}></SettingsIcon>
            <Typography component="div" variant="body1">
              <Box color="text.primary">Settings</Box>
            </Typography>
          </Button>

        </div>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
