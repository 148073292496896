var firebase = require('firebase');

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  clientId: process.env.REACT_APP_FIREBASE_CLIENT_ID,
  scopes: [
    'email',
    'profile',
    'https://mail.google.com/'
  ],
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'
  ]
};


const app = firebase.initializeApp(config)
const database = app.database()

export { app, firebase, database, config }