import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: colors.grey[900],
    main: colors.grey[900],
    light: colors.grey[500]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A700,
    light: colors.blue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    // primary: colors.grey[900],
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[500],
  // icon: colors.blueGrey[600],
  icon: 'rgba(0, 0, 0, 0.54)',
  background: {
    default: '#F4F6F8',
    paper: white
  },
  // divider: colors.grey[200]
  divider: '#E5E5E5'
};
